







































import Vue from "vue";

import { notifyError, notifySuccess } from "@/components/Notification";
import { AxiosError } from "axios";
import {mapActions} from 'vuex';
export default Vue.extend({
    name: "btn-edit-nomenclator",
    props:{
       uuid:String,
       id:Number,
       name:String,
       type:String

    },
    data() {
        return {
            DialogEdit: false,
            value:"" ,
            loading:false
        }
    },
    mounted(){
        this.value = this.name
    },
    methods:{
        ...mapActions("crmSettingsModule", [     
      "actUpdateNomenclator",
    ]),
        cancel() {
      this.DialogEdit = false;    
    },

     update(id: string, name: string): void {
      this.actUpdateNomenclator({
        id: id,
        name: name,
        type: this.type ? this.type : "Ocupation",
      })
        .then(() => {
          notifySuccess("The ocupation has been updated");
        })
        .catch((error: any) => {
        this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    }
});
